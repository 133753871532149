import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { AppEnv, EnvType } from 'meta/app'
import { RootState, useAppSelector } from 'store'
import { AppSelectors } from 'store/app'

const envSelector = createDraftSafeSelector(
  (state: RootState) => state,
  (state: RootState) => AppSelectors.getEnv()(state)
)

export const useEnv = (): AppEnv => useAppSelector(envSelector)

export const useAppEnv = (): EnvType => useEnv().APP_ENV as EnvType
export const useAppleClientId = (): string => useEnv().APPLE_CLIENT_ID
export const useBlogApiBase = (): string => useEnv().BLOG_API_BASE
export const useBlogApiKey = (): string => useEnv().BLOG_API_KEY
export const useBackendApiBase = (): string => useEnv().BACKEND_API_V1_BASE
export const useDataDogClientToken = (): string => useEnv().DATADOG_CLIENT_TOKEN
export const useDisableSatispay = (): boolean => useEnv().DISABLE_SATISPAY
export const useEnableSalesforceChat = (): boolean => useEnv().ENABLE_SALESFORCE_CHAT === 'true'
export const useFacebookAppId = (): string => useEnv().FACEBOOK_APPID
export const useFacebookDomainVerification = (): string => useEnv().FACEBOOK_DOMAIN_VERIFICATION
export const useFacebookPixelId = (): string => useEnv().FACEBOOK_PIXELID
export const useFunUrl = (): string => useEnv().FUN_URL
export const useGoogleMapsApiKey = (): string => useEnv().GOOGLE_MAPS_API_KEY
export const useGoogleAnalyticsId = (): string => useEnv().GOOGLE_ANALYTICS_ID
export const useGoogleClientId = (): string => useEnv().GOOGLE_CLIENT_ID
export const useGoogleRecaptchaKey = (): string => useEnv().GOOGLE_RECAPTCHA_V3_PUBLIC_KEY
export const useMaintenanceMode = (): boolean => useEnv().MAINTENANCE_MODE === 'true'
export const useOTPConfigKey = (): string => useEnv().OTP_CONFIG_KEY
export const usePosBankRedirectUrl = (): string => useEnv().POS_BANK_REDIRECT_URL
export const useProxyApiBase = (): string => useEnv().PROXY_API_BASE
export const usePrCookieTime = (): string => useEnv().PR_COOKIE_TIME
export const useStripeApiKey = (): string => useEnv().STRIPE_API_KEY
export const useTikTokPixelId = (): string => useEnv().TIKTOK_PIXELID
export const useS2SToken = (): string => useEnv().S2S_TOKEN
export const useUrlAppAndroid = (): string => useEnv().URL_APP_ANDROID
export const useUrlAppIos = (): string => useEnv().URL_APP_IOS
export const useUrlBase = (): string => useEnv().URL_BASE
export const useUrlBusiness = (): string => useEnv().URL_BUSINESS
export const useUrlCloudfront = (): string => useEnv().URL_CLOUDFRONT
export const useVenueMapsType = (): string => useEnv().VENUE_MAPS_TYPE
